// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { capitalizeFLetter, ajaxPerso } from '../../fnc'

const refConst = require("../../constants.js").v

export default function Modal(props){

	const initialState = {
		submited: false,
		reqRunning: false
	}

	const [reqRunning, setReqRunning] = useState(initialState.reqRunning);
	const [submited, setSubmited] = useState(initialState.submited);
	const [version, setVersion] = useState("0.1.0");
	const [isOpen, setIsOpen] = useState(isOpen);

	const refDesc = useRef();	

	useEffect(() => {
		triggerModal(true);
	}, [])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let triggerModal = (refresh) => {
		if(!isOpen){
			props.modal({
				key: props.title,
				show: refresh? false : true,
				title: <span>{props.title ?? "Emtty modal title"} {"version" in props && props.version? <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{version}</h6> : ""}</span>,
				options: {
					width: "modal-lg"
				},
				html: (close) => {
					return props.body(close) ?? "Empty modal"
				}, exit: (popup, close) => {
					close()
				}, 
				ok: "submit" in props && props.submit? {
					title: "Submit",
					fnc: (popup, close) => {
						if(props.submit){
							props.submit(popup, close)
						}
					}
				} : null,
				return: {
					title: reqRunning? "Please wait" : "Close",
					fnc: (popup, close) => {
						if(!submited){
							resetPage()
							close()
						}
					}
				}
			});
			setIsOpen(true)
		}
	}

	console.info("ASdadadasd", props);

	return (<div>{triggerModal()}</div>);
}